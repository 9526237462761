@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
body,
.container,
.container-fluid {
  max-width: 100%;
  overflow-x: hidden;
}
